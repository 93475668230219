/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import BrandIcon from 'parts/BrandIcon';
import Button from 'elements/Button';
import github from '../assets/images/github.png';
import fb from '../assets/images/fb.webp';
import insta from '../assets/images/Instagram.webp';
import linkdin from '../assets/images/linkdin.png';
import loc from '../assets/images/loc.webp';
import mail from '../assets/images/mail.webp';
import phone from '../assets/images/phone.png';
import whatsapp from '../assets/images/whatsapp.png';

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Growing Your Business
              <br />
              Is Our Calling
            </p>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Office</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={mail}
                alt="githib"
                width={30}
                style={{ marginRight: '10px' }}
              />
              <Button
                href="mailto:contact@dynamicsoftsol.com"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                contact@dynamicsoftsol.com
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={loc}
                alt="githib"
                width={30}
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <p className="text-lg text-gray-400 font-light">
                Business Bay, Dubai, UAE
              </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={phone}
                alt="githib"
                width={30}
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <Button
                href="tel:+966509775245"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                +966509775245
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={whatsapp}
                alt="whatsapp"
                width={30}
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <Button
                href="https://api.whatsapp.com/send?phone=966509775245"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                966 50 977 5245
              </Button>
            </div>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Social</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={fb}
                alt="githib"
                width={30}
                style={{ marginRight: '10px' }}
              />
              <Button
                href="https://www.facebook.com"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                Facebook
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={insta}
                alt="githib"
                width={30}
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <Button
                href="https://www.instagram.com/ahmed122616/"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                Instagram
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={linkdin}
                alt="githib"
                width={30}
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <Button
                href="https://www.linkedin.com/in/ahmed-ali-866802155/"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                LinkedIn
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={github}
                alt="githib"
                width={30}
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <Button
                href="https://github.com"
                type="link"
                target="_blank"
                className="flex text-lg text-gray-400 font-light hover:underline"
                isExternal
              >
                Github
              </Button>
            </div>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2023 - All rights reserved - DynamicSoft
          </p>
          <div className="flex-row">
            <p className="inline-block text-lg text-gray-400 font-light">
              Made with &#x2764; by&nbsp;
            </p>
            <Button
              href="https://www.instagram.com/ahmed122616/"
              type="link"
              target="_blank"
              className="text-lg text-theme-purple font-light"
              isExternal
            >
              Ahmed Ali
            </Button>
          </div>
        </div>
      </div>
      <df-messenger
        intent="WELCOME"
        chat-title="DS_Agent"
        agent-id="15aa409b-544d-4624-adeb-135c223ca744"
        language-code="en"
      >
        a
      </df-messenger>
    </div>
  );
}
